<template>
  <router-link v-if="src && $isSubscribed" class="cursor-pointer" :to="{ path: '/' }">
    <img width="100px" height="100%" loading="lazy" :src="src" alt="SuperScoute" />
  </router-link>
  <router-link v-else-if="$isSubscribed" class="cursor-pointer" :to="{ path: '/' }">
    <img
      width="100%"
      height="100%"
      loading="lazy"
      :src="`${this.assetURL}/logo.png`"
      alt="SuperScoute"
    />
  </router-link>
  <img
    v-else
    width="100%"
    height="100%"
    loading="lazy"
    class="mt-4"
    :src="`${this.assetURL}/logo.png`"
    alt="SuperScoute"
  />
</template>

<script>
// @group SVG
export default {
  name: 'Logo',

  props: {
    color: { type: String, default: '' },
    width: { type: String },
    src: { type: String, default: '' }
  },

  data() {
    return {
      colorInput: this.color
    }
  },

  watch: {
    color(value) {
      this.colorInput = value
    }
  }
}
</script>
