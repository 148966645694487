import omit from 'lodash/omit'
import Api from './api/api.service'

const baseServiceUrl = 'link'

export default {
  getHistory(params) {
    return Api().get(`${baseServiceUrl}/history`, { params })
  },
  getPath(linkId, params) {
    return Api().get(`${baseServiceUrl}/path/${linkId}`, { params })
  },
  getAll(linkId, params) {
    return Api().get(`${baseServiceUrl}/list/${linkId}`, { params })
  },
  getAllOriginal(id, params) {
    return Api().get(`${baseServiceUrl}/original/${id}`, { params })
  },
  get(id, options = {}) {
    return Api(options).get(`${baseServiceUrl}/${id}`)
  },
  save(payload, options = {}) {
    if (payload._id) {
      return Api(options).patch(`${baseServiceUrl}/${payload._id}`, omit(payload, ['_id']))
    } else {
      return Api(options).post(`${baseServiceUrl}`, payload)
    }
  },
  delete(id) {
    return Api().delete(`${baseServiceUrl}/${id}`)
  },
  manage(linkId, payload) {
    return Api().patch(`${baseServiceUrl}/manage/${linkId}`, payload)
  },
  notification(linkId, payload) {
    return Api().post(`${baseServiceUrl}/notification/${linkId}`, payload)
  },
  sync(linkId, payload, options = {}) {
    return Api(options).patch(`${baseServiceUrl}/sync/${linkId}`, payload)
  }
}
