import omit from 'lodash/omit'
import Api from './api/api.service'

const baseServiceUrl = 'collection'

export default {
  getOnlyList(params) {
    return Api().get(`${baseServiceUrl}/list`, { params })
  },
  getAll(params) {
    return Api().get(`${baseServiceUrl}/all`, { params })
  },
  get(id) {
    return Api().get(`${baseServiceUrl}/${id}`)
  },
  save(payload, options = {}) {
    if (payload._id) {
      return Api(options).patch(`${baseServiceUrl}/${payload._id}`, omit(payload, ['_id']))
    } else {
      return Api().post(`${baseServiceUrl}`, payload)
    }
  },
  manageLocation(id, payload) {
    return Api().patch(`${baseServiceUrl}/manage-location/${id}`, payload)
  },
  delete(id) {
    return Api().delete(`${baseServiceUrl}/${id}`)
  }
}
