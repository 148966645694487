import Api from './api/api.service'

export default {
  login(params) {
    return Api().post(`login`, params)
  },
  logout() {
    return Api().post(`logout`)
  },
  register(params) {
    return Api().post('user/register', params)
  },
  verifyEmail(params) {
    return Api().post('verify/email', params)
  },
  resendVerifyEmail(params) {
    return Api().post('resend/email', params)
  },
  forgotPassword(params) {
    return Api().post('forgot-password', params)
  },
  resetPassword(params) {
    return Api().post('reset-password', params)
  },
  version() {
    return Api().get('version');
  },
}
