import omit from 'lodash/omit'
import Api from './api/api.service'

export default {
  save(userId, user, hideSuccessMessage) {
    // userId should be user._id
    const id = user._id || userId
    return Api({
      hideSuccessMessage
    }).patch(`user/${id}`, omit(user, ['_id', 'email']))
  },
  updateSettings(params, hideSuccessMessage) {
    return Api({
      hideSuccessMessage
    }).patch(`user/settings`, params)
  },
  get() {
    return Api().get('user/me')
  },
  getAll(params) {
    return Api().post('user/a/all', params)
  },
  getById(params) {
    return Api().get('user', { params })
  },

  getNames(params) {
    return Api().get(`user/names`, { params })
  },
  action(params) {
    return Api().post('user/a/action', params)
  },
  sendEmail(params) {
    return Api().post('user/a/send-email', params)
  },
  uniqueEmail(params) {
    return Api({
      hideErrorMessage: true,
      hideSuccessMessage: true
    }).post('user/unique', params)
  },
}
