import { authStore } from '@stores/auth'
import head from 'lodash/head'

export default [
  {
    path: '/terms-policies',
    name: 'policies',
    component: () => import('@views/Policies.vue').then((c) => c.default || c)
  },
  {
    path: '/l/:shareId',
    name: 'location.share.view',
    component: () => import('@views/location/share/view/index.vue'),
    meta: {
      breadcrumb: (name) => name || 'Shared location',
      event: (route) => `/l/${route.params.shareId}`,
      link: (route) => `/l/${route.params.shareId}`
    },
    children: [
      {
        path: 'photos/:rank',
        name: 'share.focus.view',
        component: () => import('@views/location/share/view/focusView.vue'),
        meta: {
          breadcrumb: (name) => name || 'Focus view',
          event: (route) => `${route.params.shareId}`,
          link: (route) => `/l/${route.params.shareId}/photos/${route.params.rank}`
        }
      }
    ]
  },

  {
    path: '/c/:shareId',
    name: 'collection.share.view',
    component: () => import('@views/collections/share/view/index.vue'),
    meta: {
      event: (route) => `/c/${route.params.shareId}`
    }
  },

  {
    path: '/terms-conditions',
    name: 'terms',
    component: () => import('@views/Terms.vue').then((c) => c.default || c)
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@views/Test.vue').then((c) => c.default || c)
  },
  {
    path: '/test2',
    name: 'test2',
    component: () => import('@views/Test2.vue')
  }
]
