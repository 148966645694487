import cloneDeep from 'lodash/cloneDeep'
import some from 'lodash/some'
import startCase from 'lodash/startCase'
import pick from 'lodash/pick'

import { Base } from './base.model'
import { UtilityService, UserService } from '@/services'

export class User extends Base {
  excludes = ['accounts', 'stats', 'ip', 'id']
  _loading = false
  _secondaryEmail = {
    _index: 0,
    _loading: false,
    _invalid: false,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    isVerified: false,
    isPrimary: false,
    isNew: true,
    notification: {
      system: false,
      company: false
    }
  }

  _secondaryPhone = {
    _index: 0,
    _loading: false,
    _invalid: false,
    phone: undefined,
    firstName: undefined,
    lastName: undefined,
    isVerified: false,
    isPrimary: false,
    isNew: true,
    notification: {
      system: false,
      company: false
    }
  }

  _username = undefined
  _email = undefined

  policy = false
  phone = undefined
  countryCode = undefined
  bio = undefined
  displayName = undefined
  companyName = undefined
  email = undefined
  firstName = undefined
  lastName = undefined
  username = undefined
  source = 'custom'
  accountType = undefined
  accountTypeOther = undefined

  pronoun = undefined

  notification = {
    monthly: false
  }
  socialLink = {
    instagram: undefined,
    twitter: undefined,
    facebook: undefined
  }

  account = {}
  location = {}
  strategy = {}
  activeCampaign = {}
  contactPref = {
    email: false,
    phone: false
  }

  media = []
  accounts = []
  secondaryEmails = []
  secondaryPhones = []
  deletedMediaIds = []
  defaultAccount = null
  referralCode = undefined

  constructor(user = {}) {
    super(user)
    this.set(user)
    this._email = this.email
    this._username = this.username
    this.policy = Boolean(this.policy);
    this.setSecondaryEmails()
    this.setSecondaryPhones()
  }

  setSecondaryEmails() {
    this.secondaryEmails.forEach((email, index) => {
      email._index = index
      email._isVerified = email.isVerified
      email._loading = false
    })
  }

  setSecondaryPhones() {
    this.secondaryPhones.forEach((phone, index) => {
      phone._index = index
      phone._isVerified = phone.isVerified
      phone._loading = false
    })
  }

  setUsername() {
    this.username = UtilityService.slugify(this.username)
  }

  async makeUsername() {
    if (this.email) {
      this.username = (this.email || '').split('@')[0]
      const existingUser = await UserService.getById({
        username: this.username.toLowerCase()
      })
      if (existingUser && existingUser.data) {
        this.username = this.username + '-' + Math.random().toString(36).substr(2, 5)
      }
    } else {
      this.username = Math.random().toString(36).substr(2, 5)
    }
  }

  removeEmail(index) {
    this.secondaryEmails.splice(index, 1)
    this.secondaryEmails.forEach((email, index) => (email._index = index))
  }

  removePhone(index) {
    this.secondaryPhones.splice(index, 1)
    this.secondaryPhones.forEach((phone, index) => (phone._index = index))
  }

  addEmail(email) {
    this.secondaryEmails.push({
      ...cloneDeep(email || this._secondaryEmail),
      _index: this.secondaryEmails.length
    })
  }

  addPhone(phone) {
    this.secondaryPhones.push({
      ...cloneDeep(phone || this._secondaryPhone),
      _index: this.secondaryPhones.length
    })
  }

  hasStrategy() {
    return some(this.strategy, (item) => !!item)
  }

  getAccount(accountId) {
    if (accountId) {
      const account = this.accounts.find((account) => account._id == accountId)
      if (account) {
        return account
      } else {
        this.accounts[0]
      }
    }

    return this.accounts[0]
  }

  validate() {
    return !this.secondaryEmails.some((email) => {
      return email._invalid
    })
  }

  getSetSecondaryEmails() {
    if (
      this.secondaryEmails.length &&
      this.secondaryEmails.every((email) => !email.notification.system)
    ) {
      const email = this.secondaryEmails.find((email) => email.isPrimary)

      if (email) {
        email.notification.system = true
      }
    }
  }

  getDeletedMediaIds() {
    return this.deletedMediaIds.filter((key) => {
      return !this.media.some((item) => item.key == key)
    })
  }

  get() {
    this.setUsername()
    this.getSetSecondaryEmails()

    const user = super.get()
    user.firstName = startCase(user?.firstName) || undefined
    user.lastName = startCase(user.lastName) || undefined
    user.email = (user.email || '').toLowerCase() || undefined
    user.deletedMediaIds = this.getDeletedMediaIds()

    delete user.account

    return user
  }

  getPublicInfo() {
    const user = this.get()
    return pick(user, [
      'firstName',
      'lastName',
      'companyName',
      'username',
      'media',
      'bio',
      'defaultAccount'
    ])
  }

  getPrivateInfo() {
    const user = pick(this.get(), ['phone', 'location'])
    user.phone = user.phone || '' // PATCH: phone number library set undefined instead of ''
    return user
  }

  getAccountInfo() {
    const user = this.get()
    return pick(user, ['secondaryEmails'])
  }

  getNotificationInfo() {
    const user = this.get()
    return pick(user, ['contactPref', 'notification', 'secondaryEmails', 'secondaryPhones'])
  }

  getBankInfo() {
    const user = this.get()
    return pick(user, ['account'])
  }

  getPaymentInfo() {
    const user = this.get()
    return pick(user, ['accountType', 'accountTypeOther'])
  }

  getAdminAccountInfo() {
    const user = this.get()
    return pick(user, [
      'username',
      'firstName',
      'lastName',
      'companyName',
      'languages',
      'color',
      'media'
    ])
  }

  getAdminPrivateInfo() {
    const user = this.get()
    return pick(user, [
      'email',
      'phone',
      'contactPref',
      'pronoun',
      'location',
      'disciplines',
      'secondaryEmails',
      'secondaryPhones'
    ])
  }

  getAdminSocialInfo() {
    const user = this.get()
    return pick(user, ['socialLink'])
  }

  getSource(user = {}) {
    if (user.providerId == 'google.com') {
      return 'google'
    } else if (user.providerId == 'facebook.com') {
      return 'facebook'
    } else if (user.providerId == 'twitter.com') {
      return 'twitter'
    } else {
      return 'custom'
    }
  }

  getToken(user = {}) {
    return user.profile && user.profile.id && user.profile.id.toString()
  }
  getStrategyId(user = {}) {
    return user.strategy[user?.source]?.toString()
  }

  getLoginInfo() {
    return {
      token: this.getToken(this),
      source: this.getSource(this),
      login: this.email || this.username,
      password: this.password
    }
  }

  getRegisterInfo() {
    const user = this.get()
    return pick(user, [
      'source',
      'media',
      'email',
      'phone',
      'countryCode',
      'firstName',
      'lastName',
      'displayName',
      'username',
      'policy',
      'password'
    ])
  }

  getFollowUpInfo() {
    const user = this.get()
    return pick(user, ['email', 'firstName', 'lastName', 'location', 'policy'])
  }

  async setUserInfo(user = {}) {
    this.displayName = user.profile.name

    if (user.providerId == 'google.com') {
      this.source = 'google'
      this.strategy.google = user.profile.id
      this.firstName = user.profile.given_name
      this.lastName = user.profile.family_name
      this.email = user.profile.email
      await this.makeUsername()
      this.media = [{ original: user.profile.picture }]
    } else if (user.providerId == 'facebook.com') {
      this.source = 'facebook'
      this.strategy.facebook = user.profile.id
      this.email = user.profile.email
      await this.makeUsername()
      this.firstName = user.profile.first_name
      this.lastName = user.profile.last_name
      this.media = [{ facebook: user.profile.id, original: user.profile.picture.data.url }]
    } else if (user.providerId == 'twitter.com') {
      this.source = 'twitter'
      this.strategy.twitter = user.profile.id.toString()
      this.email = user.profile.email
      await this.makeUsername()
      this.media = [{ original: user.profile.profile_image_url_https }]
      // this.bio = user.profile.description;
    }
  }
}
