<template>
  <div class="h-100">
    <div class="hidden h-full lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <nav class="flex flex-1 flex-col pt-4">
          <ul role="list" class="flex flex-1 flex-col gap-y-7 ">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <router-link :to="item.link">
                    <div
                      class="w-screen"
                      :class="[
                        item.current
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      ]"
                    >
                      <Icon class="text-primary size-6" :icon="`${item.icon}`"></Icon>
                      {{ item.name }}
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- <li class="-mx-6 mt-auto mb-28">
              <router-link to="#">
                <div class="pa-2">Contact SuperScout</div>
              </router-link>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { VenueMixin } from '@mixins'
export default {
  mixins: [VenueMixin],
  data() {
    return {
      sidebarOpen: false
    }
  }
}
</script>
