import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'

import axios from 'axios'
import superscout from './index'
import { StorageService, EventService } from '@services'

export default (options = {}) => {
  const config = {
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_API_URL
  }
  config.headers = {}

  const _accountId = StorageService.getSessionItem('accountId')
  if (_accountId) {
    config.headers.accountId = _accountId
  }

  const axiosInstance = axios.create(config)

  const requestInterceptor = axiosInstance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => requestErrorHandler(error)
  )

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => responseErrorHandler(error)
  )

  const sanitize = (data) => {
    if (data) {
      forEach(data, (value, key) => {
        if (value === '' || (Array.isArray(value) && !value.length)) {
          delete data[key]
        }
      })

      superscout.options.sanitize(data, options)
    }
  }

  const requestHandler = (_request) => {
    const request = cloneDeep(_request)

    if (request.method == 'get') {
      sanitize(request.params)
    }

    if (request.method == 'post') {
      sanitize(request.data)
    }

    if (request.data) {
      delete request.data.createdBy
      delete request.data.updatedBy
      if (!request.data.allowCreatedAt) {
        delete request.data.createdAt
      }
      delete request.data.allowCreatedAt
      delete request.data.updatedAt
    }

    superscout.options.requestHandler(request, options)

    axiosInstance.interceptors.request.eject(requestInterceptor)

    return request
  }

  const requestErrorHandler = (error) => {
    superscout.options.requestErrorHandler(error.response, options)

    axiosInstance.interceptors.request.eject(requestInterceptor)

    return Promise.resolve(false)
  }

  const responseHandler = (response) => {
    if (response?.data?.plans && response?.data?.plans?.isCanceled == true) {
      EventService.emit('checkIsSubscribed', response.data.plans)
    }
    superscout.options.responseHandler(response, options)

    axiosInstance.interceptors.response.eject(responseInterceptor)

    return response
  }

  const responseErrorHandler = (error) => {
    superscout.options.responseErrorHandler(error.response, options)

    axiosInstance.interceptors.response.eject(responseInterceptor)

    return Promise.resolve(false)
  }

  return axiosInstance
}
