import routeHelper from './helper'

export default [
  {
    path: '/search',
    name: 'search.view',
    component: () => import('@views/SearchView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      hideFooter: true,
      breadcrumb: (name) => name || 'Library',
      event: () => 'Search',
      link: (route) => `/`
    },
    beforeEnter: routeHelper.beforeEnter
  },

  {
    path: '/location/contact',
    name: 'location.contact.view',
    component: () => import('@views/location/create/LocationContact.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:accountId/:locationId',
    name: 'location.view',
    component: () => import('@views/location/view/index.vue'),
    meta: {
      requiresAuth: true,
      eventCategory: 'Location View',
      event: (route) => `${route.params.locationId}`,
      link: (route) => `/${route.params.accountId}/${route.params.locationId}`,
      breadcrumb: (name) => name || 'Location View',
      hideFooter: true
    },
    beforeEnter: routeHelper.beforeEnter,
    children: [
      {
        path: 'photos/:rank',
        name: 'photos.view',
        component: () => import('@views/photos/view/index.vue'),
        meta: {
          requiresAuth: true,
          eventCategory: 'Location Photo View',
          breadcrumb: (name) => name || 'Photos Gallery',
          event: (route) => `${route.params.locationId}`,
          link: (route) =>
            `/${route.params.accountId}/${route.params.locationId}/photos/${route.params.rank}`,
          hideFooter: true
        }
      },
      {
        path: 'export',
        name: 'location.export',
        component: () => import('@components/location/view/VenuePhotosForExport.vue'),
        meta: {
          requiresAuth: true,
          eventCategory: 'Location Photo Export',
          breadcrumb: (name) => name || 'Export photos selection',
          event: (route) => `${route.params.locationId}`,
          link: (route) => `/${route.params.accountId}/${route.params.locationId}/export`,
          hideActionBar: true,
          hideFooter: true
        }
      }
    ]
  }

  // {
  //   path: '/terms-policies',
  //   name: 'page.terms',
  //   component: () => import('@views/Terms.vue').then((c) => c.default || c),
  //   meta: {
  //     title: 'Terms & Conditions',
  //     event: () => 'Terms & Conditions'
  //   }
  // }
]
